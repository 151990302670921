import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class ReportService {

  getSummary(dateRange) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'report/summary/' + dateRange.start + '/' + dateRange.end)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  sendReport(id) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'report/summary/' + id + '/send')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  confirmReport(id) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'report/summary/' + id + '/confirm')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  getAgents(dateRange, branchId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'report/agent/' + dateRange.start + '/' + dateRange.end + (branchId?'/'+branchId:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  getMarkets(dateRange, branchId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'report/market/' + dateRange.start + '/' + dateRange.end + (branchId?'/'+branchId:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  getOthers(dateRange, branchId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'report/other/' + dateRange.start + '/' + dateRange.end + (branchId?'/'+branchId:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  getAccruals(itemId, dateRange, branchId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'report/accrual/' + itemId + '/' + dateRange.start + '/' + dateRange.end + (branchId?'/'+branchId:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  export(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'report/export', data, {
        responseType: 'blob'
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

}

export default new ReportService()
